import React from 'react'
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"




const GbiBridged = () => {
  const { contentImage1, contentImage2, contentImage3 } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logoImage: file(relativePath: { eq: "logo-inverted.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage1: file(relativePath: { eq: "gallery/lamiaautowrap-toyota-aygo-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage2: file(relativePath: { eq: "gallery/lamiaautowrap-touareg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        contentImage3: file(relativePath: { eq: "gallery/lamiaautowrap-mg-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      
    `
  )

  const image3 = getImage(contentImage1)
  const image4 = getImage(contentImage2)
  const image5 = getImage(contentImage3)


  // Use like this:



  



  return (
    
    <Layout>
 
    

 
    <main>
      <div id="content" className="subp">
      <section className="row text-center headings">
      <h1 className='h1-heading'>Foliering</h1>
      <span className="intro-text">
      Foliering ger ett bra skydd mot repor och slag eller annan åverkan. Filmen lägger sig som heltäckande skydd på både detaljer och större ytor. När folien tas bort på rätt sätt syns inga spår efteråt. Om den underhålls rätt håller folieringen i sex till sju år. Filmen finns i alla tänkbara färger. Så när du vill ha en bil som talar sitt eget språk folierar vi utvalda detaljer
på din bil, eller helfoliering i rätt färg för dig.</span> 
      </section>
     <section className="row box">

      <div className="col col-12">
      <GatsbyImage image={image3} alt={"image3"} className="content-image" width="1000"/>
      <GatsbyImage image={image5} alt={"image5"} className="content-image" width="1000"/>
      <GatsbyImage image={image4} alt={"image4"} className="content-image" width="1000"/>



      </div>
   

      </section>
      <section className='text-center'>      <Link className="link" to="/referenser">Klicka här för att se fler referenser</Link></section>
      
     <section className="row box">

      <div className="col col-12 text-center">
      <div className="inner-content"> 
      <h2 className="text-center mb-3 wide">

     3 års garanti
      </h2>

      <span>När du anlitar oss kan du räkna med att jobbet blir fint utfört. Du kan kalla oss perfektionister som aldrig ger sig förrän det blir bra. Vi sätter kundens önskemål i fokus och vill alltid att du ska vara nöjd. Därför kan vi erbjuda nöjd kund-garanti och 3 års garanti på all foliering.</span>
      </div>
      </div>

</section>

     </div>
     </main>
   

  </Layout>


  )
}

export default GbiBridged


